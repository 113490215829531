import React, { useContext } from 'react';
import classNames from 'classnames';
import { RicosContext } from 'ricos-context';
import PropTypes from 'prop-types';
import { D as DIVIDER_TYPE } from './viewer-loadable2.js';
import 'wix-rich-content-common';
import '@loadable/component';
import 'ricos-types';

var dividerStyles = {"divider-container":"dKqrT","divider-container--double":"kWB74","divider":"q-ZRn","divider--single":"-Kkxw","divider--double":"_1g8me","divider--dashed":"kHSS9","divider--dotted":"iP2-l","divider--small":"SUR5x","divider--small--mobile":"_2-1Ky","divider--medium":"GugBJ","divider--large--mobile":"jPQy2","divider--medium--mobile":"ejwb8","divider--large":"VvpFp","divider--left":"k20Oq","divider--center":"g-5Xq","divider--right":"EAAB4","divider--fill-parent":"k9qCV","svgFixerContainer":"NHfRK","emptyChar":"FwBrc"};

var withSafariSvgCaretFixer = function withSafariSvgCaretFixer(Component) {
  return /* @__PURE__ */React.createElement("div", {
    className: dividerStyles.svgFixerContainer
  }, Component, /* @__PURE__ */React.createElement("div", {
    className: dividerStyles.emptyChar
  }, "\xA0"));
};

function _createForOfIteratorHelperLoose(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (t) return (t = t.call(r)).next.bind(t); if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var o = 0; return function () { return o >= r.length ? { done: !0 } : { done: !1, value: r[o++] }; }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = function __defNormalProp(obj, key, value) {
  return key in obj ? __defProp(obj, key, {
    enumerable: true,
    configurable: true,
    writable: true,
    value: value
  }) : obj[key] = value;
};
var __spreadValues = function __spreadValues(a, b) {
  for (var prop in b || (b = {})) if (__hasOwnProp.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols) {
    for (var _iterator = _createForOfIteratorHelperLoose(__getOwnPropSymbols(b)), _step; !(_step = _iterator()).done;) {
      var prop = _step.value;
      if (__propIsEnum.call(b, prop)) __defNormalProp(a, prop, b[prop]);
    }
  }
  return a;
};
var lineProps = function lineProps(width, lineDistance) {
  if (lineDistance === void 0) {
    lineDistance = 1;
  }
  return {
    x2: width,
    y1: lineDistance,
    y2: lineDistance
  };
};
var getLines = function getLines(type, width, multilineDistance) {
  if (multilineDistance === void 0) {
    multilineDistance = 7;
  }
  var linePropsArr = [lineProps(width)];
  if (type === DIVIDER_TYPE.LINE_DOUBLE) {
    linePropsArr.push(lineProps(width, multilineDistance));
  }
  return linePropsArr;
};
var DividerLine = function DividerLine(_ref) {
  var type = _ref.type,
    size = _ref.size,
    alignment = _ref.alignment,
    width = _ref.width,
    multilineDistance = _ref.multilineDistance,
    styles = _ref.styles,
    className = _ref.className,
    fillParent = _ref.fillParent,
    isMobile = _ref.isMobile;
  var linesPropsArr = getLines(type, width, multilineDistance);
  var lineClassName = classNames(styles.divider, styles["divider--" + type], styles["divider--" + size + (isMobile ? "--mobile" : "")], styles["divider--" + alignment], fillParent ? styles["divider--fill-parent"] : "", className);
  return withSafariSvgCaretFixer( /* @__PURE__ */React.createElement("svg", {
    className: lineClassName
  }, linesPropsArr.map(function (lineProp, i) {
    return /* @__PURE__ */React.createElement("line", __spreadValues({
      key: i
    }, lineProp));
  })));
};
DividerLine.propTypes = {
  type: PropTypes.string.isRequired,
  size: PropTypes.string,
  alignment: PropTypes.string,
  styles: PropTypes.object.isRequired,
  className: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  multilineDistance: PropTypes.number,
  fillParent: PropTypes.bool,
  isMobile: PropTypes.bool
};
var DividerLine$1 = DividerLine;

var ROOT = "divider";

var DividerViewer = function DividerViewer(_ref) {
  var componentData = _ref.componentData;
  var _a, _b, _c;
  var _useContext = useContext(RicosContext),
    isMobile = _useContext.isMobile;
  var lineStyle = (_a = componentData.lineStyle) == null ? void 0 : _a.toLowerCase();
  var className = classNames(dividerStyles["divider-container"], dividerStyles["divider-container--" + lineStyle], isMobile && dividerStyles["divider-container--mobile"]);
  return /* @__PURE__ */React.createElement("div", {
    className: className,
    "data-hook": ROOT + " divider-" + lineStyle
  }, /* @__PURE__ */React.createElement(DividerLine$1, {
    type: lineStyle,
    width: "100%",
    size: (_b = componentData.width) == null ? void 0 : _b.toLowerCase(),
    alignment: (_c = componentData.alignment) == null ? void 0 : _c.toLowerCase(),
    styles: dividerStyles,
    isMobile: isMobile
  }));
};

var Divider = function Divider(_ref) {
  var node = _ref.node,
    nodeIndex = _ref.nodeIndex,
    NodeViewContainer = _ref.NodeViewContainer;
  return /* @__PURE__ */React.createElement(NodeViewContainer, {
    key: node.id,
    node: node,
    nodeIndex: nodeIndex
  }, /* @__PURE__ */React.createElement(DividerViewer, {
    componentData: node.dividerData
  }));
};

export { Divider as default };

import 'wix-rich-content-plugin-divider/dist/loadable/viewer/es/styles.viewer.min.global.css'